import AbstractStorageLogger from './abstractStorage.js';

export const NAME = 'session';

export default class SessionLogger extends AbstractStorageLogger {
  constructor(version) {
    super(version, NAME);
    this.storage = window.sessionStorage;
    this.init();
  }
}
