const now = (function() {
  if (Date.now) {
    return Date.now.bind(Date);
  } else {
    return function() {
      return (new Date()).getTime();
    };
  }
})();

export default now;
