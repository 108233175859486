/*eslint camelcase: "off"*/
import WPSLog from '../utils/console';
import STRING_CONSTRAINTS from '../constants/stringconstraints';
import URL_FILTER from '../constants/urlfilter';

class Resolvable {
  constructor(context) {
    this.context = context;
  }

  get info() {
    return 'resolvable';
  }

  get result() {
    let name = this.info || this.constructor.name;
    WPSLog.info(name, ' start');
    let result = this.resolve();
    WPSLog.info(name, ' end', result);
    return result;
  }

  resolve() {
    return false;
  }
}

class OperatorList extends Resolvable {
  constructor(list, context, filterConfig) {
    super(context);
    let promiseList = list.map(
      filterOrOperator => {
        if (!filterOrOperator) {
          return undefined;
        }
        return filter_or_operator_union(filterOrOperator, context, filterConfig).result;
      });
    this.filteredList = Promise.all(promiseList).then(filterResults => filterResults.filter(result => result !== undefined));
  }

  get info() {
    return 'operator list';
  }

}

class OperatorAnd extends OperatorList {
  get info() {
    return 'and';
  }

  resolve() {
    return this.filteredList.then(
      filteredList => !filteredList.some(item => !item));
  }
}

class OperatorOr extends OperatorList {
  get info() {
    return 'or';
  }

  resolve() {
    return this.filteredList.then(
      filteredList => filteredList.some(item => item));
  }
}

class OperatorNot extends Resolvable {
  constructor(obj, context, filterConfig) {
    super(context);
    this.obj = obj;
    this.filterConfig = filterConfig;
  }

  get info() {
    return 'not';
  }

  resolve() {
    if (this.obj) {
      if (this.obj.filter) {
        return !filter_union(this.obj.filter, this.context, this.filterConfig).result;
      }

      if (this.obj.hasOwnProperty('result')) {
        return !this.obj.result;
      }
    }

    return undefined;
  }
}

class FalseFilter extends Resolvable {
  constructor(obj, context) {
    super(context);
    this.obj = obj;
  }

  get info() {
    return 'false';
  }

  resolve() {
    return false;
  }
}

class TrueFilter extends Resolvable {
  constructor(obj, context) {
    super(context);
    this.obj = obj;
  }

  get info() {
    return 'TrueFilter';
  }

  resolve() {
    return true;
  }
}

class Device extends Resolvable {
  constructor(obj, context) {
    super(context);
    this.mobile = obj.mobile_only;
    this.desktop = obj.desktop_only;
  }

  get info() {
    return 'device filter';
  }

  resolve() {
    return this.context.mobile === this.mobile && this.context.desktop === this.desktop;
  }
}

class ShowOnlyForLogginUsers extends Resolvable {
  constructor(obj, context) {
    super(context);
    this.showOnlyForLogginUsers = obj;
  }

  get info() {
    return 'show only for logged in users';
  }

  resolve() {
    return this.context.isUserloggedIn === this.showOnlyForLogginUsers;
  }
}

class ImpressionLimit extends Resolvable {
  constructor(obj, context) {
    super(context);
    const minutes = parseInt(obj.minutes_between_impressions);
    const impressions = parseInt(obj.maximum_impressions);
    this.minutesBetweenImpressions = isNaN(minutes) ? null : minutes;
    this.maximumImpressions = isNaN(impressions) ? null : impressions;
    if (this.minutesBetweenImpressions === 20 * 60 + 2) { // fixing session intervall
      this.minutesBetweenImpressions = 20;
    }
  }

  get info() {
    return 'impression limit';
  }

  resolve() {
    let impression = this.context.impression;
    let ts = this.context.lastChange;
    let currentTS = this.context.now;

    let interval = Math.min(this.minutesBetweenImpressions, 5256000) * 1000 * 60;

    let intervalResult = true;
    let impressionResult = true;

    if (this.minutesBetweenImpressions !== null && this.minutesBetweenImpressions !== 0) {
      WPSLog.info('ts', ts);
      WPSLog.info('current ts', currentTS);
      WPSLog.info('interval', interval);
      intervalResult = currentTS - ts >= interval;
      WPSLog.info('interval result', intervalResult);
    } else if (this.minutesBetweenImpressions === 0) {
      intervalResult = false;
    }

    if (this.maximumImpressions !== null) {
      WPSLog.info('impression', impression);
      impressionResult = impression < this.maximumImpressions;
      WPSLog.info('impression result', impressionResult);
    }

    return intervalResult && impressionResult;

  }
}

class DisableAfterConversion extends Resolvable {
  constructor(obj, context) {
    super(context);
    this.disableAfterConversion = obj;
  }

  get info() {
    return 'disable after conversion';
  }

  resolve() {
    if (this.disableAfterConversion) {
      return !this.context.hasConversion;
    }
    return true;
  }
}

class StringConstraint extends Resolvable {
  constructor(obj, context) {
    super(context);
    this.argument = obj.argument;
    this.operator = obj.operator;
    this.left = '';
  }

  get info() {
    return `string constraint ${this.left} ${this.operator} ${this.argument}`;
  }

  resolve() {
    const decodedUrl = decodeURI(this.left);
    const decodedArgument = decodeURI(this.argument);
    if (this.operator === STRING_CONSTRAINTS.IS) {
      return decodedUrl === decodedArgument;
    }

    if (this.operator === STRING_CONSTRAINTS.STARTS_WITH) {
      return decodedUrl.startsWith(decodedArgument);
    }

    if (this.operator === STRING_CONSTRAINTS.ENDS_WITH) {
      WPSLog.info('string constraint:', this);
      return decodedUrl.endsWith(decodedArgument);
    }

    if (this.operator === STRING_CONSTRAINTS.CONTAINS) {
      return decodedUrl.includes(decodedArgument);
    }

    return false;
  }
}

class URLPattern extends Resolvable {
  constructor(obj, context) {
    super(context);
    this.applyTo = obj.apply_to;
    this.constraint = new StringConstraint(obj.constraint);
  }

  static get SLASHER_START() {
    return 'START';
  };

  static get SLASHER_END() {
    return 'END';
  };

  static get SLASHER_BOTH() {
    return 'BOTH';
  };

  static get SLASHER_NONE() {
    return 'NONE';
  };

  get info() {
    return 'url pattern';
  }

  get URL() {
    return this.context.documentURI || this.context.url;
  }

  static getHostname(U) {
    let www = U.hostname.slice(0, 4);
    return www === 'www.' ? U.hostname.slice(4) : U.hostname;
  }

  static slasher(path, where) {
    let result = path;
    if (where === URLPattern.SLASHER_START || where === URLPattern.SLASHER_BOTH) {
      result = result.startsWith('/') ? result : '/' + result;
    }

    if (where === URLPattern.SLASHER_END || where === URLPattern.SLASHER_BOTH) {
      result = result.endsWith('/') ? result : result + '/';
    }

    return result;
  }

  resolve() {
    const url = new URL(this.URL);

    if (this.applyTo === URL_FILTER.FULL_URL) {
      this.constraint.left = this.URL;
    }

    if (this.applyTo === URL_FILTER.URL_WITHOUT_PROTOCOL) {
      this.constraint.left = this.URL;
    }

    if (this.applyTo === URL_FILTER.PATH_ONLY) {
      let slasherOperator = URLPattern.SLASHER_NONE;
      if (this.constraint.operator === STRING_CONSTRAINTS.IS) {
        slasherOperator = URLPattern.SLASHER_BOTH;
      } else if (this.constraint.operator === STRING_CONSTRAINTS.STARTS_WITH) {
        slasherOperator = URLPattern.SLASHER_START;
      } else if (this.constraint.operator === STRING_CONSTRAINTS.ENDS_WITH) {
        slasherOperator = URLPattern.SLASHER_END;
      }

      this.constraint.argument = URLPattern.slasher(this.constraint.argument, slasherOperator);
      this.constraint.left = URLPattern.slasher(url.pathname, URLPattern.SLASHER_BOTH);
    }

    if (this.applyTo === URL_FILTER.DOMAIN_ONLY) {
      let arg = this.constraint.argument.startsWith('http') ? this.constraint.argument : 'http://' + this.constraint.argument;
      this.constraint.argument = URLPattern.getHostname(new URL(arg));
      this.constraint.left = URLPattern.getHostname(url);
    }

    return this.constraint.result;
  }
}

class ReferrerURLPattern extends URLPattern {
  get info() {
    return 'referrer pattern';
  }

  get URL() {
    return this.context.referrer;
  }
}

class SiteSection extends Resolvable {
  constructor(obj, context) {
    super(context);
    this.siteSection = obj;
  }

  get info() {
    return 'site section';
  }

  resolve() {
    return this.context.siteSection === this.siteSection;
  }
}

class SessionLimit extends Resolvable {
  constructor(obj, context) {
    super(context);
    this.maximumImpressions = obj.maximum_impressions || null;
  }

  get info() {
    return 'session limit';
  }

  resolve() {
    let impression = this.context.sessionImpression;

    if (this.maximumImpressions !== null) {
      return impression < this.maximumImpressions;
    }
  }

}

class UserSessionLimit extends Resolvable {
  constructor(obj, context) {
    super(context);
    this.displayOnceEveryUserSession = obj;
  }

  get info() {
    return 'display once every user session';
  }

  resolve() {
    return !this.displayOnceEveryUserSession || !this.context.userSessionImpression;
  }
}

function operator_union(obj, context, filterConfig) {
  if (obj.and) {
    return new OperatorAnd(obj.and.list, context, filterConfig);
  }

  if (obj.or) {
    return new OperatorOr(obj.or.list, context, filterConfig);
  }

  if (obj.not) {
    return new OperatorNot(obj.not, context, filterConfig);
  }
}

function webextend_union(obj, context) {
  if (obj.site_section) {
    return new SiteSection(obj.site_section, context);
  }
  return new TrueFilter(obj.webextend);
}

function filter_union(obj, context, filterConfig) {
  let filterName = Object.keys(obj)[0];
  if (!!filterConfig && !!filterConfig.always_true_filters && filterConfig.always_true_filters.includes(filterName)) {
    WPSLog.info(filterName + ' is set to always eval true');
    return new TrueFilter(filterName);
  }

  if (obj.webextend) {
    return webextend_union(obj.webextend, context);
  }

  if (obj.device) {
    return new Device(obj.device, context);
  }
  if (obj.impression_limit) {
    return new ImpressionLimit(obj.impression_limit, context);
  }

  if (obj.disable_after_conversion !== undefined) {
    //temporary fix for WCH-1327
    return new DisableAfterConversion(true, context);
  }

  if (obj.requesting_url) {
    return new URLPattern(obj.requesting_url, context);
  }

  if (obj.referring_url) {
    return new ReferrerURLPattern(obj.referring_url, context);
  }

  if (obj.session_limit) {
    return new SessionLimit(obj.session_limit, context);
  }

  if (obj.show_only_for_logged_in_users !== undefined) {
    //temporary fix for WCH-1327
    return new ShowOnlyForLogginUsers(true, context);
  }

  if (obj.display_once_every_user_session !== undefined) {
    return new UserSessionLimit(obj.display_once_every_user_session, context);
  }

  return new FalseFilter(obj, context);
}

function filter_or_operator_union(obj, context, filterConfig) {
  if (!obj) {
    return new TrueFilter('nothing to do');
  }
  if (obj.filter) {
    return filter_union(obj.filter, context, filterConfig);
  }

  if (obj.operator) {
    return operator_union(obj.operator, context, filterConfig);
  }
}

export default filter_or_operator_union;
