const INFO = 1;
const WARN = 2;
const ERROR = 3;

let LOG_LEVEL = ERROR;

export default class WPSLog {
  static log(level, ...args) {
    if (LOG_LEVEL <= level) {
      console.log.apply(console, args);
    }
  }

  static info() {
    WPSLog.log(INFO, ...arguments);
  }

  static warn() {
    WPSLog.log(WARN, ...arguments);
  }

  static error() {
    WPSLog.log(ERROR, ...arguments);
  }

  static setLevel(newLevel) {
    LOG_LEVEL = newLevel;
  }

  static get INFO() {
    return INFO;
  }

  static get WARN() {
    return WARN;
  }

  static get ERROR() {
    return ERROR;
  }
}

window.WPSLogLevel = WPSLog.setLevel;
