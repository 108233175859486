export default class Context {
    constructor(customerId, url, prevUrl, language, cart, checkout, views, category, searchTerm,
                userLoggedIn, emailHash, externalContactId, useLegacyContentService) {
        this.customerId = customerId;
        this.url = url;
        this.prevUrl = prevUrl;
        this.language = language;
        this.cart = cart;
        this.checkout = checkout;
        this.views = views;
        this.category = category;
        this.searchTerm = searchTerm;
        this.userLoggedIn = userLoggedIn;
        this.emailHash = emailHash;
        this.externalContactId = externalContactId;
        this.useLegacyContentService = useLegacyContentService || false;
    }

    getAsQueryParams() {
        let queryParams = {
            params: {
                url: this.url,
                prev_url: this.prevUrl,
                lang: this.language,
                ca: this.getItemsAsQueryParam(this.cart),
                co: this.getItemsAsQueryParam(this.checkout),
                v: this.getViewedItemsAsQueryParam(this.views),
                vc: this.getCategoriesAsQueryParam(this.category),
                q: this.searchTerm,
                uli: this.userLoggedIn
            }
        };
        if (this.emailHash) {
            queryParams.params.eh = this.emailHash;
        }
        if (this.externalContactId) {
            queryParams.params.ci = this.externalContactId;
        }
        return queryParams;
    }

    getItemsAsQueryParam(items) {
        if (!items) return null;
        return items.map(this._getItemAsQueryParam).join('|');
    }

    _getItemAsQueryParam(item) {
        return "i:" + (item.i || "") + ",p:" + (item.p || "") + ",q:" + (item.q || "");
    }

    getViewedItemsAsQueryParam(items) {
        if (!items) return null;
        return items.map(this._getViewedItemAsQueryParam).join('|');
    }

    _getViewedItemAsQueryParam(item) {
        return "i:" + (item.i || "");
    }

    getCategoriesAsQueryParam(categories) {
        if (!categories) return null;
        return categories.join('|');
    }

    getUseLegacyContentService() {
        return this.useLegacyContentService;
    }
}
