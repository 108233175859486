import { createDomFragment } from '../renderers/renderUtils.js';
import WPSLog from '../utils/console.js';
import EVENTS from '../constants/events.js';
import styleIsolator from "./styleIsolator";

function imageBanner(wpContent) {
  return createDomFragment(
    `<img src="${wpContent.imageUrl}" data-wps-href="${wpContent.linkUrl}" style="max-width:100%;max-height:100%">`);
}

function mapLinksToTrackableLinks(fragment) {
  fragment.querySelectorAll('[href]').forEach((node) => {
    const child = node.firstElementChild;
    node.dataset.wpsHref = (child && child.getAttribute('href') ? child : node)
      .getAttribute('href');
    node.removeAttribute('href');
    return node;
  });
  return fragment;
}

function createIframeForForm(fragment) {
  fragment.querySelectorAll('form').forEach(node => {
    let iframe = document.createElement('iframe');
    let id = 'iframe_' + Date.now() + '_' + Math.floor(Math.random() * 1000);
    iframe.style.display = 'none';
    iframe.setAttribute('name', id);
    iframe.setAttribute('sandbox', ['allow-same-origin', 'allow-scripts'].join(' '));
    fragment.appendChild(iframe);
    node.setAttribute('target', id);
    iframe.addEventListener('load', e => {
      WPSLog.info('load event');
      if (!e.target.dataset.init) {
        e.target.dataset.init = true;
      } else {
        let emailInput = node.querySelector('input[name="inp_3"]');
        let optinInput = node.querySelector('input[name="optin"]');
        let campaignGoal = node.getAttribute('data-wps-form-type');
        let contactdbUpdates = {};
        Array.from(node.querySelectorAll('input[name]')).forEach(inp => {
          let name = inp.getAttribute('name');
          if (name.startsWith('inp_')) {
            contactdbUpdates[name.replace('inp_', '')] = inp.value;
          }
        });

        let data = { iframe };

        if (emailInput) {
          data.email = emailInput.value;
        }
        if (optinInput) {
          data.optin = optinInput.checked;
        }
        if (Object.keys(contactdbUpdates).length) {
          data.contactdb_updates = contactdbUpdates;
        }
        if (campaignGoal) {
          data.campaignGoal = campaignGoal;
        }

        //should only fire if register.php post was successful
        let event = new CustomEvent('wpsFormSubmitted', { detail: { eventType: EVENTS.SUBMIT, data }, bubbles: true });
        node.dispatchEvent(event);
      }
    });
  });
  return fragment;
}

function wpContentToHtml(content, namespaceId) {
  let fragment;
  if (content.image_and_link) {
    fragment = imageBanner(content.image_and_link);
  }
  if (content.html) {
    fragment = createDomFragment(content.html.html);
    styleIsolator.isolateStyle(fragment, namespaceId);
    fragment = mapLinksToTrackableLinks(fragment);
    fragment = createIframeForForm(fragment);
  }
  return fragment;
}

export default { wpContentToHtml };
