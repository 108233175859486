import AbstractLoggerFrontend from '../../src/loggerFrontend/abstract';
import WPSLog from '../utils/console';

class ConsoleLogger extends AbstractLoggerFrontend {
  send(id, type, timestamp, params) {
    WPSLog.info(`[console logger] ad: ${id} ${type} ${timestamp} ${JSON.stringify(params)}`);
  }
}

export default new ConsoleLogger();
