/* global BUILD_ENV */
import AbstractLoggerFrontend from '../../src/loggerFrontend/abstract';
import EVENTS from '../../src/constants/events';
import store from './../utils/store.js';

const RECOMMENDER_URL = BUILD_ENV === 'test' ? '/test' : process.env.RECOMMENDER_URL;
const EU_RECOMMENDER_URL = BUILD_ENV === 'test' ? '/test' : process.env.EU_RECOMMENDER_URL;

const ScarabEvents = {
  [EVENTS.SUBMIT]: 'SUBMIT',
  [EVENTS.CLICK]: 'CLICK',
  [EVENTS.CANCEL]: 'CANCEL',
  [EVENTS.IMPRESSION]: 'SHOW'
};

export default class ScarabLoggerFrontend extends AbstractLoggerFrontend {

  constructor(moduleConfig) {
    super();
    if (moduleConfig && moduleConfig.default_config && moduleConfig.default_config.eu_only) {
      this.recUrl = `${EU_RECOMMENDER_URL}`;
    } else {
      this.recUrl = `${RECOMMENDER_URL}`;
    }
    this.logURL = `${this.recUrl}/is_alive`;
    this.merchantId = null;
    window.SCARAB_OK_ = null;
  }

  [EVENTS.CLICK](id, timestamp, params) {
    let newParams = { query: params };
    this.send(id, EVENTS.CLICK, timestamp, newParams);
  }

  [EVENTS.SUBMIT](id, timestamp, params) {
    const optin = store.get(id).state.optin;
    const campaignGoal = store.get(id).state.campaignGoal;
    const contactdbUpdates = store.get(id).state.contactdb_updates;
    params = { query: {} };

    if (optin) {
      params.query.optin = optin;
    }
    if (campaignGoal) {
      params.query.campaignGoal = campaignGoal;
    }
    if (contactdbUpdates) {
      params.query.contactdb_updates = contactdbUpdates;
    }
    this.send(id, EVENTS.SUBMIT, timestamp, params);
  }

  send(id, type, timestamp, params = {}) {
    if (!(params instanceof Object && !Array.isArray(params))) {
      params = {};
    }
    const emailHash = store.get('emailHash');
    const customerId = store.get('customerId');

    if (emailHash) {
      params.emailHash = emailHash;
    }
    if (customerId) {
      params.customerId = customerId;
    }

    let queryParams = (params && params.query) ? params.query : null;

    const feature = `WEBPERSONALIZATION_${ScarabEvents[type]}`;
    const v = encodeURIComponent(`i:${id},t:${feature},c:AAAA`);
    const redirectTo = encodeURIComponent(this.logURL);
    const query = encodeURIComponent(JSON.stringify(queryParams || {}));

    let url = `${this.recUrl}/merchants/${this.merchantId}/?v=${v}&q=${query}&redirect_to=${redirectTo}`;

    const eh = (params && params.emailHash) ? encodeURIComponent(params.emailHash) : null;
    const ci = (params && params.customerId) ? encodeURIComponent(params.customerId) : null;

    if (eh) {
      url = url.concat(`&eh=${eh}`);
    }
    if (ci) {
      url = url.concat(`&ci=${ci}`);
    }

    const s = document.createElement('script');
    s.src = url;
    document.body.appendChild(s);
  }
}
