import Ad from '../data/ad.js';
import filterAd from '../data/filters.js';
import EVENTS from '../constants/events.js';
import WPSLog from '../utils/console';
import SITESECTION from '../constants/oldsitesection.js';
import { OverlayRenderInfo, RibbonRenderInfo, ZoneRenderInfo } from '../data/renderInfo';
import store from '../utils/store';
import { FLIPPERS, Flippers } from '../constants/flippers';
import * as SessionLogger from '../loggerBackend/session';
import * as UserSessionLogger from '../loggerBackend/userSession';

// copied over from https://github.com/emartech/webchannel-common/blob/master/packages/webchannel-js-common/src/util/promise.js#L14
// to remove webchannel-js-common as a dependency
const timeboxPromise = function(originalPromise, milisecondsToWait) {
  return Promise.race([
    originalPromise,
    new Promise((_resolve, reject) => {
      window.setTimeout(
        () => reject(new Error('Timeout after ' + milisecondsToWait + ' ms')),
        milisecondsToWait);
    })
  ]);
};

export default class AbstractTransport {
  constructor(merchantId, moduleConfig, filterConfig) {
    this.moduleConfig = moduleConfig;
    this.merchantId = merchantId;
    this.isMobile = false;
    this.isDesktop = false;
    this.siteSection = null;
    this.session = null;
    this.cohort = 'AAAA';
    this.url = null;
    this.language = '';
    this.logger = null;
    this.emailHash = false;
    this.customerId = false;
    this.isUserLoggedIn = false;
    this.filterConfig = filterConfig;
    this.flippers = new Flippers();
  }

  get visitorIdentified() {
    return !!this.emailHash || !!this.customerId;
  }

  setUrl(url) {
    this.url = url;
  }

  setLanguage(lang) {
    this.language = lang;
  }

  setMobile(isMobile) {
    this.isMobile = isMobile;
  }

  setDesktop(isDesktop) {
    this.isDesktop = isDesktop;
  }

  setSiteSection(siteSection) {
    this.siteSection = siteSection;
  }

  setSession(session) {
    this.session = session;
  }

  setEmailHash(emailHash) {
    this.emailHash = emailHash;
  }

  setCustomerId(customerId) {
    this.customerId = customerId;
  }

  setUserLoggedIn(isUserLoggedIn = true) {
    this.isUserLoggedIn = isUserLoggedIn;
  }

  getConfig() {
    let config = {
      isMobile: false,
      isDesktop: false,
      siteSection: ['*'],
      language: ['*'],
      url: ['*']
    };

    if (this.isDesktop) {
      config.isDesktop = true;
    }

    if (this.isMobile) {
      config.isMobile = true;
    }

    let siteSection = SITESECTION._fromWebextend(this.siteSection);
    if (siteSection) {
      config.siteSection.push(siteSection);
    }

    if (this.language) {
      config.language.push(this.language);
    }

    if (this.url) {
      config.url.push(this.url);
    }

    if (this.emailHash) {
      config.emailHash = this.emailHash;
    }

    if (this.customerId) {
      config.customerId = this.customerId;
    }

    return config;
  }

  async processCatalog(catalogData = []) {
    store.set({
      ads: catalogData.map(catalogItem => catalogItem.item)
    });

    let sortField = 'c_campaign_priority';
    if (this.flippers.isFlipperOn(this.moduleConfig, FLIPPERS.RANK_BASED_SORTING_ON_UI)) {
      if (catalogData.every(item => !!item.c_campaign_rank)) {
        sortField = 'c_campaign_rank';
      } else {
        WPSLog.error('Some catalog items have missing rank field, catalogData: ', { catalogData });
      }
    }

    return Promise.all(
      [...catalogData]
        .sort((a, b) => a[sortField] - b[sortField])
        .map(catalogItem => this.processV3Catalog(catalogItem))
    ).then(ads => ads.filter(ad => ad.enabled));
  }

  transformCatalogItem(catalogItem) {
    let v3Content = JSON.parse(catalogItem.c_content_v3);

    // let jsHooks = v3Content.js_hooks;
    let trigger = v3Content.trigger;
    let documentInfo = v3Content.document;
    let filter = v3Content.filter;

    return { trigger, documentInfo, filter };
  }

  processV3Catalog(catalogItem) {
    WPSLog.info('catalog item:', catalogItem);

    let { trigger, documentInfo, filter } = this.transformCatalogItem(catalogItem);

    WPSLog.info('trigger: ', trigger);
    WPSLog.info('documentInfo: ', documentInfo);
    WPSLog.info('filter: ', filter);
    let triggerInfo = { type: 'on_entry', delay: 0 };
    let renderInfo = {};

    if (trigger.on_entry && trigger.on_entry.delay_seconds) {
      triggerInfo.delay = trigger.on_entry.delay_seconds;
    }

    if (trigger.use_exit_intent) {
      triggerInfo.type = 'on_exit';
      triggerInfo.delay = 0;
    }

    if (documentInfo.overlay) {
      renderInfo = new OverlayRenderInfo(documentInfo.overlay.content);
    }

    if (documentInfo.zones) {
      renderInfo = new ZoneRenderInfo(documentInfo.zones.list);
    }

    if (documentInfo.ribbon) {
      renderInfo = new RibbonRenderInfo(documentInfo.ribbon.content, documentInfo.ribbon.options);
    }

    let adData = {
      id: catalogItem.item,
      campaignId: catalogItem.c_campaign_id,
      campaignPriority: catalogItem.c_campaign_priority,
      language: catalogItem.c_language,
      url: catalogItem.c_campaign_url,
      renderInfo,
      triggerInfo,
      constrains: trigger.constrains
    };

    let ad = Ad.createAd(adData, this.logger, this.moduleConfig);

    let adFilter = filterAd(filter, this.createContext(ad), this.filterConfig);

    if (ad.isInvalid) {
      ad.enabled = false;
      return Promise.resolve(ad);
    }
    return timeboxPromise(
      Promise.resolve(adFilter.result),
      // 200 ms max wait per ad
      200).then(
      (isEnabled) => {
        ad.enabled = isEnabled;
        if (ad.init && ad.enabled) {
          ad.init();
        }
        return ad;
      },
      (e) => {
        // if there was a timeout or unexpected exception, disable ad
        WPSLog.info('error resolving adFilter', e);
        ad.enabled = false;
        return ad;
      }
    );
  }

  send() {
    return Promise.resolve();
  }

  createContext(ad) {
    return {
      desktop: this.isDesktop,
      mobile: this.isMobile,
      siteSection: this.siteSection,
      language: this.language,
      url: this.url,
      documentURI: document.documentURI || document.URL,
      referrer: document.referrer,
      ad: ad,
      now: Date.now(),
      impression: ad.getCount(EVENTS.IMPRESSION),
      lastChange: ad.getLastChange(EVENTS.IMPRESSION),
      hasConversion: ad.hasConversion(),
      sessionImpression: ad.getCount(EVENTS.IMPRESSION, SessionLogger.NAME),
      userSessionImpression: ad.getCount(EVENTS.IMPRESSION, UserSessionLogger.NAME),
      isUserloggedIn: this.isUserLoggedIn
    };
  }
}
