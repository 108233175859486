export const FLIPPERS = {
  RANK_BASED_SORTING_ON_UI: 'rank_based_sorting_on_ui',
  GA_MULTI_TRACKER_SUPPORT: 'ga_multi_tracker_support'
};


export class Flippers {
  isFlipperOn(moduleConfig, flipper) {
    return moduleConfig.default_config && moduleConfig.default_config[flipper];
  };
}
