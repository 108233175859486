import { BrowserGlobals } from './browser_globals.js';

let win = BrowserGlobals.window;
let doc = BrowserGlobals.document;
let nav = win.navigator;

export const Device = {
  device: function() {
    var device = {
      screen: {
        width: win.screen.width,
        height: win.screen.height
      }
    };
    let width;
    let height;
    try {
      width = win.innerWidth || doc.documentElement.clientWidth || doc.body.clientWidth;
    } catch (e) { width = 0; }
    try {
      height = win.innerHeight || doc.documentElement.clientHeight || doc.body.clientHeight;
    } catch (e) { height = 0; }
    device.viewport = {
      width: width,
      height: height
    };
    device.isTablet = !!nav.userAgent.match(/(iPad|SCH-I800|xoom|kindle)/i);
    device.isPhone = !device.isTablet && !!nav.userAgent.match(
      // eslint-disable-next-line max-len
      /(iPhone|iPod|blackberry|android 0.5|htc|lg|midp|mmp|mobile|nokia|opera mini|palm|pocket|psp|sgh|smartphone|symbian|treo mini|Playstation Portable|SonyEricsson|Samsung|MobileExplorer|PalmSource|Benq|Windows Phone|Windows Mobile|IEMobile|Windows CE|Nintendo Wii)/i);
    device.isMobile = device.isTablet || device.isPhone;
    device.isDesktop = !device.isMobile;
    return device;
  },
  plugins: function() {
    let checkPlugin = name => {
      if (nav.plugins) {
        let plugin;
        let i = 0;
        let length = nav.plugins.length;
        for (; i < length; i++) {
          plugin = nav.plugins[i];
          if (plugin && plugin.name && plugin.name.toLowerCase().indexOf(name) !== -1) {
            return true;
          }
        }
        return false;
      }
      return false;
    };
    let checkActivexFlash = versions => {
      for (let i = 0; i < versions.length; i++) {
        try {
          new win.ActiveXObject('ShockwaveFlash.ShockwaveFlash' + versions[i]);
          return true;
        } catch (e) { /* nil */ }
      }
      return false;
    };
    return {
      flash: checkPlugin('flash') || checkActivexFlash(['.7', '.6', '']),
      silverlight: checkPlugin('silverlight'),
      java: checkPlugin('java'),
      quicktime: checkPlugin('quicktime')
    };
  },
  architecture: function() {
    let arch = nav.userAgent.match(/x86_64|Win64|WOW64|x86-64|x64\;|AMD64|amd64/) ||
      (nav.cpuClass === 'x64' ? 'x64' : 'x86');
    return {
      arch: arch,
      isX64: arch === 'x64',
      isX86: arch === 'x86'
    };
  }
};
