import Campaign from './campaign.js';
import WPSLog from '../utils/console.js';

const campaignMap = {};
export default class campaignManager {
  static get(campaignId) {
    return campaignMap[campaignId];
  }
  static set(campaignId, campaign) {
    campaignMap[campaignId] = campaign;
  }
  static add(id, priority, url) {
    let campaign = campaignManager.get(id);
    WPSLog.info('campaign:', campaign);
    if (campaign === undefined) {
      WPSLog.info('create new campaign', id, priority, url);
      campaign = new Campaign(id, priority, url);
      campaignManager.set(id, campaign);
    }

    return campaign;
  }
};

