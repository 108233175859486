import Axios from 'axios';
import WPSLog from '../utils/console'

export default class ContentFetcher {

    async fetchAds(context) {
        const baseUrl = context.getUseLegacyContentService() ? process.env.CONTENT_SERVICE_LEGACY_URL : process.env.CONTENT_SERVICE_URL;
        const campaignGetUrl = baseUrl + '/customer/' + context.customerId + '/campaigns';
        try {
            const campaigns = await Axios.get(campaignGetUrl, context.getAsQueryParams());
            return campaigns.data.ads;
        } catch (e) {
            WPSLog.error("error in contentFetcher", e);
            return [];
        }
    }
}

