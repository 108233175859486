import { getElement } from '../renderers/renderUtils.js';
import '../scss/creative.scss';
import EVENTS from '../constants/events';
import { saveEmailHash } from '../session/predict';
import store from './../utils/store.js';

export default class Creative {
  constructor() {
    this.selector = '';
    this.ad = null;
    this.fragments = [];
    this.flex = false;
    this.targetElement = null;
    this.guardElement = null;
    this.currentPage = 0;
    this.placement = null;
    this.listenersAttached = false;
  }

  get id() {
    return `${this.ad.id}-${this.ad.getCreativeIndex(this)}`;
  }

  get priority() {
    return this.ad.priority;
  }

  navigate(url, newWindow) {
    if (newWindow) {
      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      window.location.href = url;
    }
  }

  attachNavigation() {
    this.guardElement.addEventListener('click', e => {
      let wpsHrefElement = (el => {
        let result = undefined;
        do {
          if (el.dataset && (el.dataset.wpsHref || el.dataset.wpsCta)) {
            result = el;
          } else {
            el = el.parentNode;
          }
        } while (!result && el);
        return result;
      })(e.target);
      if (wpsHrefElement) {
        let url = wpsHrefElement.dataset.wpsHref;
        let CTAData = wpsHrefElement.dataset.wpsCta;
        let persist = wpsHrefElement.dataset.hasOwnProperty('wpsPersist');
        this.ad.log(EVENTS.CLICK, url || CTAData);
        if (url) {
          this.navigate(url, wpsHrefElement.getAttribute('target') === '_blank');
        }
        if (CTAData && !persist) {
          this.onEnd();
        }
      }
    });
  }

  alloc() {
    return getElement(this.selector).then(element => {
      if (!element.dataset.wpsAd) {
        this.targetElement = element;
        this.stamp();
        return Promise.resolve(this);
      }
      return Promise.reject(this);
    });
  }

  deleteOrHideChildren(targetElement) {
    if (this.isFlexible) {
      this.deleteChildren(targetElement);
    } else {
      this.hideChildren(targetElement);
    }
  }

  deleteChildren(targetElement) {
    targetElement.style.backgroundImage = 'none';
    targetElement.innerHTML = '';
  }

  hideChildren(targetElement) {
    targetElement.style.backgroundImage = 'none';
    let targetDisplay = window.getComputedStyle(targetElement).display;
    targetElement.style.display = targetDisplay === 'inline' ? 'inline-block' : targetDisplay;

    let textNodes = Array.from(targetElement.childNodes).filter(node => {
      return node.nodeType === 3;
    });

    if (textNodes.length) {
      textNodes.forEach(node => {
        if (node.cloneNode) {
          let sp = document.createElement('span');
          sp.style.display = 'inline';
          sp.appendChild(node.cloneNode());
          targetElement.replaceChild(sp, node);
        }
      });
    }

    let childCount = targetElement.childNodes.length;
    for (let i = 0; i < childCount; i++) {
      let child = targetElement.childNodes[i];
      if (child.style) {
        child.style.visibility = 'hidden';
      }
    }
  }

  placeGuardElement() {
    if (this.targetElement) {
      if (this.placement === Creative.REPLACE) {
        this.deleteOrHideChildren(this.targetElement);
        this.targetElement.appendChild(this.guardElement);
      } else {
        let newTargetElement = this.targetElement.cloneNode(true);
        this.deleteOrHideChildren(newTargetElement);
        newTargetElement.appendChild(this.guardElement);
        if (this.placement === Creative.INSERT_BEFORE) {
          this.targetElement.parentNode.insertBefore(newTargetElement, this.targetElement);
        } else if (this.placement === Creative.INSERT_AFTER) {
          this.targetElement.after(newTargetElement);
        }
      }
    }
  }

  createGuardElement() {
    let element = document.createElement('div');
    element.dataset.wpsGuard = true;
    this.guardElement = element;
    this.attachNavigation();
    if (this.isFlexible) {
      this.guardElement.style.position = 'relative';
    } else {
      this.guardElement.style.position = 'absolute';
      let targetStyle = window.getComputedStyle(this.targetElement);
      if (targetStyle && (targetStyle.position === 'static' || targetStyle.position === '')) {
        this.targetElement.style.position = 'relative';
      }
    }
    return this.guardElement;
  }

  get isFlexible() {
    return this.flex === true || this.flex.grow_horizontal &&
      this.flex.grow_vertical;
  }

  handleATags() {
    let contentHasATag = false;
    this.fragments.forEach((fragment, index) => {
      if (fragment.querySelectorAll('a').length || fragment.querySelectorAll('img[data-wps-href]').length) {
        contentHasATag = true;
      }
    });

    if (contentHasATag) {
      let element = this.targetElement;
      while (element.parentNode !== document && element.tagName !== 'A') {
        element = element.parentNode;
      }
      if (element.tagName === 'A') {
        element.removeAttribute('href');
      }
    }
  }

  stamp() {
    let element = this.targetElement;
    if (element) {
      element.dataset.wpsAd = `wps_${this.id}`;
      this.targetElement = element;
      return this;
    }
  }

  render(targetElement) {
    let element = targetElement || this.guardElement;
    if (element && this.fragments[this.currentPage]) {
      element.innerHTML = '';
      element.appendChild(this.fragments[this.currentPage]);
    }

    if (!this.listenersAttached) {
      element.addEventListener('wpsFormSubmitted', e => {
        const optin = e.detail.data.optin;
        const contactdbUpdates = e.detail.data.contactdb_updates;
        const campaignGoal = e.detail.data.campaignGoal;
        const email = e.detail.data.email;
        let adInfo = { state: {} };

        if (email && ScarabUtil) {
          let emailHash = ScarabUtil.hashEmail(email);
          saveEmailHash(emailHash);
        }

        const adId = this.ad.id;
        const adState = store.get(adId);
        if (optin) {
          adInfo.state.optin = optin;
        }
        if (contactdbUpdates) {
          adInfo.state.contactdb_updates = contactdbUpdates;
        }
        if (campaignGoal) {
          adInfo.state.campaignGoal = campaignGoal;
        }

        const newAdState = Object.assign({}, adState, adInfo);

        store.set({ [adId]: newAdState });

        this.ad.log(e.detail.eventType);
        this.stepToNextPage();
      });
      this.listenersAttached = true;
    }
    return this;
  }

  stepToNextPage() {
    this.currentPage++;
    if (this.currentPage < this.fragments.length) {
      this.render();
    } else {
      if (this.onEnd) {
        this.onEnd();
      }
    }
  }

  static get REPLACE() { return 'REPLACE'; }

  static get INSERT_AFTER() { return 'INSERT_DOM_AFTER'; }

  static get INSERT_BEFORE() { return 'INSERT_DOM_BEFORE'; }

  static createCreative(selector, fragments, ad, flex, placement) {
    let result = new Creative();
    result.ad = ad;
    result.selector = selector;
    result.fragments = fragments;
    result.flex = flex || result.flex;
    result.placement = placement || Creative.REPLACE;
    return result;
  }
}
