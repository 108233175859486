const WEBEXTEND = {
  PRODUCT_VIEW: 'PRODUCT_VIEW',
  CATEGORY: 'CATEGORY',
  POST_PURCHASE: 'POST_PURCHASE',
  SEARCH_RESULTS: 'SEARCH_RESULTS',
  LANDING_PAGE: 'LANDING_PAGE',
  ANY_PAGE: '*'
};

export default WEBEXTEND;
