import WPSLog from '../utils/console.js';

function isElement(element) {
  return element instanceof HTMLElement;
}

function isFragment(element) {
  return element instanceof DocumentFragment;
}

function isThenable(element) {
  return element && !!element.then;
}


function getElement(selectorOrElement) {
  if (isThenable(selectorOrElement)) {
    return selectorOrElement;
  } else if (isElement(selectorOrElement)) {
    return Promise.resolve(selectorOrElement);
  } else {
    return new Promise(function(resolve, reject) {
      let selector = selectorOrElement;
      let element = document.querySelector(selector);
      if (element) {
        resolve(element);
      } else {
        let interval;
        setTimeout(() => {
          if (interval) {
            clearInterval(interval);
          }
          reject(`missing element for ${selectorOrElement}`);
        }, 5000);
        interval = setInterval(() => {
          let element = document.querySelector(selector);
          WPSLog.info(selector);
          if (element) {
            clearInterval(interval);
            resolve(element);
          }
        }, 60);
      }
    });
  }
}

function parseHtmlWithScripts(html) {
  let frag = document.createDocumentFragment();
  let div = document.createElement('div');
  div.innerHTML = html;
  while (div.firstChild) {
    frag.appendChild(div.firstChild);
  }

  function walk(node) {
    node.childNodes.forEach(innerNode => {
      if (innerNode.tagName === 'SCRIPT') {
        let newTag = document.createElement('SCRIPT');
        newTag.innerHTML = innerNode.innerHTML;
        if (innerNode.hasAttributes()) {
          var attrs = innerNode.attributes;
          for (var i = attrs.length - 1; i >= 0; i--) {
            newTag.setAttribute(attrs[i].name, attrs[i].value);
          }
        }
        node.replaceChild(newTag, innerNode);
      } else {
        walk(innerNode);
      }
    });
  }

  walk(frag);

  return frag;
}

function createDomFragment(stringOrFragment) {
  if (isElement(stringOrFragment) || isFragment(stringOrFragment)) {
    return stringOrFragment;
  }

  return parseHtmlWithScripts(stringOrFragment);
}


function checkForStamp(creatives) {
  return Promise.all(creatives.map(creative => creative.targetElement))
    .then(elements => elements.every(element => !element.dataset.wpsAd));
}

export { isElement, isFragment, getElement, createDomFragment, checkForStamp };
