import WEBEXTEND from './webextend.js';

const SITESECTION = {
  PRODUCT_VIEW: 'productPage',
  CATEGORY: 'categoryPage',
  POST_PURCHASE: 'receiptPage',
  SEARCH_RESULTS: 'searchResultPage',
  LANDING_PAGE: 'landingPage',
  _fromWebextend: wx => {
    let lastKey = null;
    const found = Object.keys(WEBEXTEND).some(key => {
      lastKey = key;
      return WEBEXTEND[key] === wx;
    });

    return found ? SITESECTION[lastKey] : undefined;
  }
};

export default SITESECTION;
