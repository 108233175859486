import now from '../utils/datetime.js';

const lastId = type => `last_${type}`;

export default class AbstractLoggerBackend {
  constructor(version, name = 'default') {
    this.version = version;
    this.name = name;
  }

  now() {
    return now();
  }

  handleIncrement(adId, type, now) {
    // WARNING: this is NOT a reference, the data getter (should) always creates a new instance from localStorage
    let data = this.data;
    if (!data[adId]) {
      data[adId] = {};

      data[adId][type] = 0;
    }
    data[adId][type] = data[adId][type] ? data[adId][type] + 1 : 1;
    data[adId][lastId(type)] = now || this.now();
    this.data = data;
  }

  handleCheck(adId, type) {
    let data = this.data;
    return data[adId] && data[adId][type] ? true : false;
  }

  getCount(adId, type) {
    let data = this.data;
    return data[adId] && parseInt(data[adId][type]) || 0;
  }

  getLastChange(adId, type) {
    let data = this.data;
    return data[adId] && parseInt(data[adId][lastId(type)]) || 0;
  }

  getLog(adId) {
    let data = this.data;
    return data[adId];
  }
}
