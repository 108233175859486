import AbstractStorageLogger from './abstractStorage.js';
import { storageFactory } from '../utils/storageFactory';

export default class StorageLogger extends AbstractStorageLogger {
  constructor(version, name) {
    super(version, name);
    this.storage = storageFactory();
    this.init();
  }
}
