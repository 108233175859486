import WPSLog from '../utils/console';
import Logger from '../../src/data/logger';
import StorageLoggerBackend from '../loggerBackend/storage';
import SessionLoggerBackend from '../loggerBackend/session';
import UserSessionLoggerBackend from '../loggerBackend/userSession';
import AbstractTransport from '../../src/transports/abstract';
import Context from '../contentFetcher/context';

export default class FilteringTransport extends AbstractTransport {

  constructor(wpMerchantId, moduleConfig, loggerBackendVersion, filterConfig, contentFetcher) {
    super(wpMerchantId, moduleConfig, filterConfig);
    this.userSessionLoggerBackend = new UserSessionLoggerBackend();
    this.logger = new Logger(
      new StorageLoggerBackend(loggerBackendVersion),
      new SessionLoggerBackend(loggerBackendVersion),
      this.userSessionLoggerBackend);
    this.contentFetcher = contentFetcher;
  }

  send() {
    this.userSessionLoggerBackend.refresh();
    const config = this.getConfig();
    const context = this.createNewContext(this.scarabContext, config);
    return super.send()
      .then(() => this.contentFetcher.fetchAds(context))
      .then(campaigns => this.processCatalog(campaigns));
  }

  createNewContext(scarabContext, config) {
    const languageFiltered = config.language.filter(e => e !== '*')[0];
    const suiteCustomerId = this.moduleConfig.default_config.customer_id;

    return new Context(
      suiteCustomerId,
      document.location.href,
      document.referrer,
      languageFiltered,
      scarabContext.cart,
      scarabContext.checkouts,
      scarabContext.views,
      scarabContext.category,
      scarabContext.searchTerm,
      this.isUserLoggedIn,
      this.emailHash,
      this.customerId,
      this.moduleConfig.default_config.use_legacy_content_service
    );
  }

  transformCatalogItem(catalogItem) {
    WPSLog.info('filtered catalog item', catalogItem);
    let result = super.transformCatalogItem(catalogItem);
    if (catalogItem && catalogItem.filter) {
      result.filter = catalogItem.filter;
    }

    return result;
  }

  setScarabContext(transaction) {
    this.scarabContext = transaction.transaction;
  }
}
