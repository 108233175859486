import Creative from '../data/creative.js';
import wpContent from '../wpcontent/index.js';
import WPSLog from '../utils/console.js';

export default {
  init: {
    value: function() {
      this.creatives = this.renderInfo.content.map(zone => {
        let fragments = zone.content.map(content =>
          wpContent.wpContentToHtml(content, this.getNamespaceId()));

        return Creative.createCreative(zone.selector, fragments, this, zone.zone_flex_settings, zone.placement);
      });
    }
  },

  _render: {
    value: function() {
      WPSLog.info('zone init');
      return Promise.all(this.creatives.map(creative => creative.alloc()))
        .then(creatives => this.listen().then(() => creatives))
        .then(creatives => creatives.forEach(creative => {
          creative.handleATags();

          const guardElement = creative.createGuardElement();
          guardElement.dataset.wpsNamespaceId = this.getNamespaceId();

          creative.placeGuardElement();
          creative.render();
        }));
    }
  }
};
