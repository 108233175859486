import Storage from './storage.js';

const TEST_KEY = '_wp_storage_test';
const TEST_VAL = 'val';

function storageFactory(w = window) {
  function validate(parent, name) {
    try {
      parent[name].setItem(TEST_KEY, TEST_VAL);
      if (parent[name].getItem(TEST_KEY) !== TEST_VAL) {
        return false;
      }
      return parent[name];
    } catch (e) {
      return false;
    }
  }

  return validate(w, 'localStorage') ||
          validate(w, 'sessionStorage') ||
          new Storage();
}

export { storageFactory };
