import WPSLog from '../utils/console.js';

export default class Logger {
  constructor(...backends) {
    this.backends = backends;
  }

  log(ad, eventName) {
    if (!eventName) {
      return Promise.reject(new Error('missing event name'));
    }

    if (!ad || !ad.id) {
      return Promise.reject(new Error('missing ad id'));
    }

    WPSLog.info(`event ${eventName}: ${ad.id}`);
    this.backends.forEach(backend => backend.handleIncrement(ad.id, eventName));
  }

  getLog(ad) {
    let log = this.backends.reduce((acc, backend) => {
      acc[backend.name] = backend.getLog(ad.id);
      return acc;
    }, {});

    WPSLog.info(`Log: ${JSON.stringify(log)}`);
    return log;
  }

  getCount(ad, type) {
    let count = this.backends.reduce((acc, backend) => {
      acc[backend.name] = backend.getCount(ad.id, type);
      return acc;
    }, {});

    WPSLog.info(`Log: ${JSON.stringify(count)}`);
    return count;
  }

  getLastChange(ad, type) {
    let ts = this.backends.reduce((acc, backend) => {
      acc[backend.name] = backend.getLastChange(ad.id, type);
      return acc;
    }, {});

    WPSLog.info(`Log: ${JSON.stringify(ts)}`);
    return ts;
  }
}
