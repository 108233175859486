import { BrowserGlobals } from './browser_globals.js';

export const Time = {
  timezone: function() {
    // split date and grab timezone estimation.
    // timezone estimation: http://www.onlineaspect.com/2007/06/08/auto-detect-a-time-zone-with-javascript/
    let d1 = new BrowserGlobals.Date();
    let d2 = new BrowserGlobals.Date();
    d1.setMonth(0); d1.setDate(1); d2.setMonth(6); d2.setDate(1);
    return {
      tzOffset: -(new BrowserGlobals.Date().getTimezoneOffset() / 60),
      observesDst: d1.getTimezoneOffset() !== d2.getTimezoneOffset()
    };
    // Gives a browser estimation, not guaranteed to be correct.
  }
};
