import JsChannel from 'jschannel';

let Channel = window.MockJsChannel || JsChannel;

function createChannel(scope) {
  let channel = Channel.build({
    window: window.opener || window.parent,
    origin: '*',
    scope,
    debugOutput: false,
    reconnect: true
  });
  channel.bind('heartbeat', () => true);

  return channel;
}

export default createChannel;
