const isTargetInput = e => e.target && e.target.tagName && ['input', 'select'].includes(e.target.tagName.toLowerCase());

export default {
  exitListener: {
    value: function(e) {
      let vpWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      let from = e.relatedTarget || e.toElement;

      e = e ? e : window.event;
      if (isTargetInput(e)) {
        return;
      }

      if (e.clientX >= vpWidth - 20) {
        return;
      }

      if (e.clientY >= 20) {
        return;
      }

      if (!from) {
        this.exitTimeout = window.setTimeout(() => {
          this.exitResolver();
        }, 500);
      }
    }
  },
  listen: {
    value: function() {
      let exitListener = this.exitListener.bind(this);
      let result = new Promise(resolve => {
        setTimeout(() => {
          document.addEventListener('mouseout', exitListener);
        }, this.triggerInfo.delay * 1000 || 10);
        this.exitResolver = resolve;

        document.documentElement.addEventListener('mouseenter', () => {
          if (this.exitTimeout) {
            window.clearTimeout(this.exitTimeout);
          }
        });
      });

      return result.then(() => {
        document.removeEventListener('mouseout', exitListener);
      });
    }
  }
};
