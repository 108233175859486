export class Start {
  start(mainObject, _window) {
    const moduleName = 'emarsys_webpersonalization';
    const scarabUtil = _window.ScarabUtil;

    if (scarabUtil && scarabUtil.loadedModules && scarabUtil.loadedModules[moduleName]) {
      if (mainObject.go) {
        scarabUtil.loadedModules[moduleName].callback(mainObject);
      } else {
        console.error('missing go()');
      }
    }
  }
}
