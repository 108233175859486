import AbstractLoggerBackend from './abstract.js';
import { storageFactory } from '../utils/storageFactory';
import EVENTS from '../constants/events';

const makeUnique = array => [...new Set(array)];

export const NAME = 'wps-user-session';
export const USER_SESSION_TIMEOUT = 30 * 60 * 1000;

export default class UserSessionLogger extends AbstractLoggerBackend {
  constructor(storageParent = window) {
    super(1, NAME);
    this.storage = storageFactory(storageParent);
  }

  handleIncrement(adId, type) {
    if (type !== EVENTS.IMPRESSION) {
      return;
    }

    // let's create a working copy because this.data always creates a new instance
    let data = this.data;

    this.data = {
      lastActivity: this.now(),
      adImpressions: makeUnique([...data.adImpressions, adId])
    };
  }

  getCount(adId, type) {
    if (type !== EVENTS.IMPRESSION) {
      return 0;
    }

    return this.data.adImpressions.includes(adId) ? 1 : 0;
  }

  getLastChange() {
    return null;
  }

  getLog() {
    return null;
  }

  refresh() {
    // let's create a working copy because this.data always creates a new instance
    const data = this.data;

    const nowValue = this.now();

    let adImpressions = data.adImpressions;
    if (!data.lastActivity || data.lastActivity + USER_SESSION_TIMEOUT < nowValue) {
      adImpressions = [];
    }

    this.data = {
      lastActivity: nowValue,
      adImpressions
    };
  }

  get data() {
    const defaultData = {
      adImpressions: []
    };
    const storageData = JSON.parse(this.storage.getItem(this.name) || '{}');

    return Object.assign(defaultData, storageData);
  }

  set data(newData) {
    this.storage.setItem(this.name, JSON.stringify(newData));
  }
}
