import { BrowserGlobals } from './browser_globals.js';
import ogParser from '../utils/ogParser.js';

let nav = BrowserGlobals.window.navigator;


export const Locale = {
  metaLocale: function() {
    let meta = document.querySelector('meta[http-equiv="language"]');
    if (meta) {
      return meta.getAttribute('content');
    }

    return false;
  },
  langParser: function() {
    let html = document.querySelector('html');
    let result = null;

    if (html) {
      let attrs = html.attributes;
      let length = attrs.length;
      let i;

      for (i = 0; i < length; i++) {
        let attr = attrs.item(i);
        let lastOfName = attr.name.slice(-4);
        if (lastOfName === 'lang') {
          result = attr.value;
          break;
        }
      }
    }
    return result;
  },
  locale: function() {
    // eslint-disable-next-line no-extra-parens
    let result = { lang: null, country: null };
    let ogLocale = ogParser('og:locale');
    let ogLanguage = ogParser('og:language');
    let htmlLocale = Locale.langParser();
    let metaLocale = Locale.metaLocale();

    if (ogLocale) {
      [result.lang, result.country] = ogLocale.split('_');
    } else if (ogLanguage) {
      result.lang = ogLanguage;
    } else if (htmlLocale) {
      [result.lang, result.country] = htmlLocale.split('-');
    } else if (metaLocale) {
      result.lang = metaLocale;
    } else {
      let lang = (
        nav.language ||
        nav.browserLanguage ||
        nav.systemLanguage ||
        nav.userLanguage || '').split('-');
      if (lang.length === 2) {
        result.lang = lang[0];
        result.country = lang[1];
      } else if (lang[0]) {
        result.lang = lang[0];
      }
    }
    if (result.lang) {
      result.lang = result.lang.toLowerCase();
    }
    if (result.country) {
      result.country = result.country.toLowerCase();
    }
    return result;
  }
};
