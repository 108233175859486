function validContentArray(arr) {
  return arr.every(item => {
    if (item.html) {
      return !!item.html.html;
    } else if (item.image_and_link) {
      return !!item.image_and_link.imageUrl;
    }
  });
}

function zoneListToContentArray(list) {
  return list.reduce((acc, item) => acc.concat(item.content || []), []);
}

class RenderInfo {
  constructor(type, content) {
    this.type = type;
    this.content = content;
  }

  getEmpty(content) {
    return !validContentArray(content);
  }

  get isEmpty() {
    return this.getEmpty(this.content);
  }

  static get OVERLAY() {
    return 'overlay';
  }

  static get ZONE() {
    return 'zone';
  }

  static get RIBBON() {
    return 'ribbon';
  }
}

class OverlayRenderInfo extends RenderInfo {
  constructor(content) {
    // content is an array of AdContent
    super(RenderInfo.OVERLAY, content);
  }

}

class ZoneRenderInfo extends RenderInfo {
  constructor(content) {
    super(RenderInfo.ZONE, content);
  }

  get isEmpty() {
    return super.getEmpty(zoneListToContentArray(this.content));
  }
}

class RibbonRenderInfo extends RenderInfo {
  constructor(content, options) {
    // content is an array of AdContent
    super(RenderInfo.RIBBON, content);
    this.options = options;
  }
}


export {
  OverlayRenderInfo,
  ZoneRenderInfo,
  RibbonRenderInfo
};


