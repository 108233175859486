import campaignManager from './campaignmanager.js';
import renderers from '../renderers/index.js';
import triggers from '../triggers/index.js';
import WPSLog from '../utils/console.js';
import EVENTS from '../constants/events';
import store from '../utils/store';

export default class Ad {
  constructor(id, campaign) {
    this.id = id;
    this.campaign = campaign;
    this.renderInfo = {};
    this.triggerInfo = {};
    this.creatives = [];
    this.logger = null;
  }

  get priority() {
    return this.campaign.priority;
  }

  log(event, data) {
    this.stateChange(event, data);
    if (this.logger) {
      this.logger.log(this, event);
    }
  }

  getLog(loggerType = 'default') {
    if (this.logger) {
      return this.logger.getLog(this)[loggerType];
    }
    return null;
  }

  getCount(type, loggerType = 'default') {
    if (this.logger) {
      return this.logger.getCount(this, type)[loggerType];
    }
    return 0;
  }

  getLastChange(type, loggerType = 'default') {
    if (this.logger) {
      return this.logger.getLastChange(this, type)[loggerType];
    }
    return 0;
  }

  getNamespaceId() {
    return `ems-wc-ns-${this.campaign.id}`;
  }

  getCreativeIndex(creative) {
    return this.creatives.indexOf(creative);
  }

  hasConversion(loggerType = 'default') {
    const toCount = new Set([EVENTS.CLICK, EVENTS.SUBMIT]);
    const log = this.getLog(loggerType) || {};
    return Object.keys(log)
      .filter(key => toCount.has(key))
      .reduce((sum, key) => sum + parseInt(log[key], 10), 0) > 0;
  }

  listen() {
    return Promise.resolve();
  }

  render() {
    if (this._render) {
      return this._render().then(() => {
        this.log(EVENTS.IMPRESSION);
      }).catch(error => {
        WPSLog.warn('conflicting campaigns', error.id);
      });
    } else {
      return Promise.reject('missing creatives render function!');
    }
  }

  stateChange(eventType, params) {
    const adId = this.id;
    const adState = store.get(adId);
    const eventObject = {
      state: Object.assign({}, (adState && adState.state) || {}, { timestamp: Date.now(), type: eventType })
    };
    if (params) {
      eventObject.state.params = params;
    }
    const newAdState = Object.assign({}, adState, eventObject);

    store.set({
      [adId]: newAdState,
      lastAdStateChange: adId
    });
  }

  static createAd(adData, logger, config) {
    let newAd = new Ad();
    let renderInfo = adData.renderInfo;
    let triggerInfo = adData.triggerInfo;
    let renderer = renderers[renderInfo.type];
    let trigger = triggers[triggerInfo.type];
    let invalid = true;
    newAd.id = adData.id;
    newAd.logger = logger;
    newAd.campaign = campaignManager.add(adData.campaignId, adData.campaignPriority, adData.url);
    newAd.config = config;
    if (!renderInfo.isEmpty && renderer && trigger) {
      newAd.renderInfo = renderInfo;
      newAd.triggerInfo = triggerInfo;
      newAd.language = adData.language;
      Object.defineProperties(newAd, renderer);
      Object.defineProperties(newAd, trigger);
      invalid = false;
    }

    newAd.isInvalid = invalid;

    return newAd;
  }
}
