// Browser (and OS) detection
import { BrowserGlobals } from './browser_globals.js';

let nav = BrowserGlobals.window.navigator;
let win = BrowserGlobals.window;
let search = data => {
  let versionString = '';
  if (typeof data === 'object') {
    // search for string match
    for (let i = 0; i < data.length; i++) {
      let dataString = data[i].string;
      let dataProp = data[i].prop;
      versionString = data[i].versionSearch || data[i].identity;
      if (dataString) {
        if (dataString.indexOf(data[i].subString) !== -1) {
          return data[i].identity;
        }
      } else if (dataProp) {
        return data[i].identity;
      }
    }
  } else {
    // search for version number
    let index = data.indexOf(versionString);
    if (index === -1) {
      return;
    }
    return BrowserGlobals.parseFloat(data.substr(index + versionString.length + 1));
  }
};
let data = {
  browser: [
    { string: nav.userAgent, subString: 'Edge', identity: 'Edge' },
    { string: nav.userAgent, subString: 'Chrome', identity: 'Chrome' },
    { string: nav.userAgent, subString: 'OmniWeb', versionSearch: 'OmniWeb/', identity: 'OmniWeb' },
    { string: nav.vendor, subString: 'Apple', identity: 'Safari', versionSearch: 'Version' },
    { prop: win.opera, identity: 'Opera', versionSearch: 'Version' },
    { string: nav.vendor, subString: 'iCab', identity: 'iCab' },
    { string: nav.vendor, subString: 'KDE', identity: 'Konqueror' },
    { string: nav.userAgent, subString: 'Firefox', identity: 'Firefox' },
    { string: nav.vendor, subString: 'Camino', identity: 'Camino' },
    { string: nav.userAgent, subString: 'Netscape', identity: 'Netscape' },
    { string: nav.userAgent, subString: 'MSIE', identity: 'Explorer', versionSearch: 'MSIE' },
    { string: nav.userAgent, subString: 'Trident', identity: 'Explorer', versionSearch: 'rv' },
    { string: nav.userAgent, subString: 'Gecko', identity: 'Mozilla', versionSearch: 'rv' },
    { string: nav.userAgent, subString: 'Mozilla', identity: 'Netscape', versionSearch: 'Mozilla' }
  ],
  os: [
    { string: nav.platform, subString: 'Win', identity: 'Windows' },
    { string: nav.platform, subString: 'Mac', identity: 'Mac' },
    { string: nav.userAgent, subString: 'iPhone', identity: 'iPhone/iPod' },
    { string: nav.userAgent, subString: 'iPad', identity: 'iPad' },
    { string: nav.userAgent, subString: 'Android', identity: 'Android' },
    { string: nav.platform, subString: 'Linux', identity: 'Linux' }
  ]
};

export const UserAgent = {
  detect: function() {
    let ret = {
      browser: search(data.browser),
      version: search(nav.userAgent) || search(nav.appVersion),
      os: search(data.os)
    };
    if (ret.os === 'Linux') {
      let distros = ['CentOS', 'Debian', 'Fedora', 'Gentoo', 'Mandriva', 'Mageia', 'Red Hat', 'Slackware', 'SUSE', 'Turbolinux', 'Ubuntu'];
      for (let i = 0; i < distros.length; i++) {
        if (nav.userAgent.toLowerCase().match(distros[i].toLowerCase())) {
          ret.distro = distros[i];
          break;
        }
      }
    }
    return ret;
  }
};

