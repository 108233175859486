export default class Storage {
  constructor() {
    this.items = {};
  }

  get length() {
    return Object.keys(this.items).length;
  }

  clear() {
    this.items = {};
  }

  getItem(keyName) {
    let item = this.items[keyName];
    if (item === undefined) {
      return null;
    }

    return item;
  }

  key(key) {
    const keys = Object.keys(this.items).sort();
    return keys[key];
  }

  removeItem(keyName) {
    delete this.items[keyName];
  }

  setItem(keyName, keyValue) {
    this.items[keyName] = keyValue;
  }
}
