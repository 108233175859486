import AbstractLoggerBackend from './abstract.js';
import WPSLog from '../utils/console.js';

export default class MemoryLoggerBackend extends AbstractLoggerBackend {
  constructor(version, name) {
    super(version, name);
    this._data = JSON.stringify({});
  }

  get data() {
    return JSON.parse(this._data);
  }

  set data(newData) {
    this._data = JSON.stringify(newData);
    WPSLog.info(newData);
  }
}
