import * as css from '@adobe/css-tools';
import WPSLog from "../utils/console";

function isolateStyle(fragment, namespaceId) {
    const styleElements = fragment.querySelectorAll("style");
    if (styleElements) {
        styleElements.forEach((styleElement) => {
            styleElement.innerHTML = namespaceCssRules(
                styleElement.innerHTML,
                `[data-wps-namespace-id=${namespaceId}] `,
                namespaceId);
        });
    }
}

function namespaceCssRules(style, prefix, logContext) {
    try {
        const obj = css.parse(style);
        obj.stylesheet.rules.forEach(
            rule => {
                if (rule.selectors) {
                    return _prefixRule(rule, prefix);
                }

                if (rule.rules) {
                    rule.rules.forEach(_rule => _prefixRule(_rule, prefix));
                    return rule;
                }
            }
        );
        const finalCss = css.stringify(obj, {compress: false});
        WPSLog.info("namespaced css:" + finalCss);
        return finalCss;
    } catch (e) {
        console.error(`error during css namespacing. context: ${logContext}, exception: ${e.stack}`);
        return style;
    }
}

function _prefixRule(rule, prefix) {
    if (rule.selectors) {
        rule.selectors = rule.selectors.map(selector => prefix + selector);
    }
    return rule;
};

export default { isolateStyle, namespaceCssRules };
