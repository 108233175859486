import AbstractLoggerBackend from './abstract.js';

export default class AbstractStorageLogger extends AbstractLoggerBackend {
  init() {
    let data = this.storage.getItem(this.dbName);
    if (!data) {
      this.storage.setItem(this.dbName, JSON.stringify({}));
    }
  }

  get dbName() {
    return `wps-${this.version}`;
  }

  get data() {
    return JSON.parse(this.storage.getItem(this.dbName));
  }

  set data(newData) {
    this.storage.setItem(this.dbName, JSON.stringify(newData));
  }
}
