/* global BUILD_ENV */
import baseFeatures from '../features/base';
import devFeatures from '../features/dev';
import prodFeatures from '../features/prod';
import FEATURE from '../constants/features';

let features = {};

function mergeFeatures(featureObject) {
  features = Object.assign(features, featureObject);
  return features;
}

function hasFeature(featureName) {
  return !!features[featureName];
}

function getFeatures() {
  return features;
}

mergeFeatures(baseFeatures);

if (BUILD_ENV === 'dev') {
  mergeFeatures(devFeatures);
} else {
  mergeFeatures(prodFeatures);
}

export default Object.assign({}, FEATURE, { has: hasFeature, mergeFeatures, getFeatures });
