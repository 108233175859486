const SESSION_STORE_KEY = 'wpsStore';

class Store {
  constructor() {
    this.reset();
  }

  create(initialState) {
    if (initialState) {
      this.set(initialState);
    }
  }

  get previousPage() {
    return (this.sessionStateHistory[this.sessionStateHistory.length - 2] || {});
  }

  set(change) {
    const previousState = this.state;
    const state = Object.freeze(Object.assign({}, previousState, change)); // should we freeze deeply?
    this.state = state;
    this.history.push(state);
    this.sessionStateHistory[this.sessionStateHistory.length - 1] = state;
    window.sessionStorage.setItem(SESSION_STORE_KEY, JSON.stringify(this.sessionStateHistory));
    this.subscribers.forEach(cb => cb({ state, change, store: this }));
  }

  subscribe(callback, playHistory) {
    if (playHistory) {
      const state = this.state;
      callback({ state, change: state, store: this });
    }
    this.subscribers.push(callback);
  }

  get(key) {
    return this.state[key];
  }

  reset() {
    this.sessionStateHistory = JSON.parse(window.sessionStorage.getItem(SESSION_STORE_KEY)) || [];
    this.subscribers = [];
    this.state = {};
    this.history = [this.state];
    this.sessionStateHistory.push(this.state);
  }
}

const store = new Store();

export default store;
