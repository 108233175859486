import AbstractTransport from './abstract.js';
import MemoryLoggerBackend from '../loggerBackend/memory.js';
import * as UserSessionLogger from '../loggerBackend/userSession';
import * as SessionLogger from '../loggerBackend/session';
import WPSLog from '../utils/console.js';
import Logger from '../data/logger';

export default class PreviewTransport extends AbstractTransport {
  constructor(wpMerchantId, moduleConfig, channel, loggerBackendVersion, configOverride, filterConfig) {
    super(wpMerchantId, moduleConfig, filterConfig);

    this.logger = new Logger(
      new MemoryLoggerBackend(loggerBackendVersion),
      new MemoryLoggerBackend(loggerBackendVersion, SessionLogger.NAME),
      new MemoryLoggerBackend(loggerBackendVersion, UserSessionLogger.NAME));

    this.channel = channel;
    this.configOverride = configOverride;
    this._isMobile = false;
    this._isDesktop = false;
  }

  get isMobile() {
    if (this.configOverride && this.configOverride.isMobile) {
      return true;
    }

    return this._isMobile;
  }

  set isMobile(isMobile) {
    this._isMobile = isMobile;
  }

  get isDesktop() {
    if (this.configOverride && this.configOverride.isMobile) {
      return false;
    }

    return this._isDesktop;
  }

  set isDesktop(isDesktop) {
    this._isDesktop = isDesktop;
  }

  transformCatalogItem(catalogItem) {
    WPSLog.info('filtered catalog item', catalogItem);
    let result = super.transformCatalogItem(catalogItem);
    if (catalogItem && catalogItem.c_backend_filter) {
      result.filter = JSON.parse(catalogItem.c_backend_filter);
    }
    return result;
  }

  send() {
    return super.send().then(() => new Promise(resolve => {
      let params = this.getConfig();
      WPSLog.info('config:', JSON.stringify(params));
      this.channel.call({
        method: 'preview',
        params: params,
        success: result => resolve(result)
      });
    })).then(data => this.processCatalog(data));
  }
}
