/* global  ESVERSION window */
// eslint-disable-next-line no-unused-vars
import WpScarab from './utils/scarab.js';
import { Session } from './session/legacySession';
import transports from './transports/index.js';
import createChannel from './utils/channel.js';
import WPSLog from './utils/console.js';
import Features from './utils/features.js';
import { saveWpMerchantId } from './session/legacyPredict';
import store from './utils/store';
import consoleLogger from './loggerFrontend/console';
import ScarabLogger from './loggerFrontend/scarab';
import ContentFetcher from "./contentFetcher";
import GALoggerFrontend from "./loggerFrontend/ga";

const logError = message => {
    WPSLog.error(message);
};

let DEV_MODE = false;
let LOGGER_BACKEND_VERSION = 1;
let storedSession;
let storedTransport;

let webPersonalization = {
    defaultCallback(session, ad) {
        return ad.render();
    },
    showAd(session, ad) {
        let callback = this.defaultCallback;
        if (ad.displayConfig && ad.displayConfig.callback &&
            window[ad.displayConfig.callback]) {
            callback = window[ad.displayConfig.callback];
        }
        return callback(session, ad);
    },
    configureTransport(transport, session) {
        let resolution = Promise.resolve(transport);
        if (session) {
            transport.setSession(session);
            if (session.device && session.device.device) {
                if (session.device.device.isDesktop) {
                    transport.setDesktop(true);
                }
                if (session.device.device.isMobile) {
                    transport.setMobile(true);
                }
            }
            let hostname = session.hostname;
            if (hostname) {
                let www = hostname.slice(0, 4);
                transport.setUrl(hostname.slice(www === 'www.' ? 4 : 0));
            }
            transport.setLanguage(session.locale && session.locale.lang);

            if (session.predict) {
                resolution = session.predict.then(predict => {
                    WPSLog.info(predict, 'configure transport');

                    if (predict.transaction && (predict.transaction.customerId || predict.transaction.emailHash)) {
                        transport.setUserLoggedIn();
                    }

                    if (predict.siteSection) {
                        transport.setSiteSection(predict.siteSection);
                    }

                    if (predict.emailHash) {
                        transport.setEmailHash(predict.emailHash);
                    }

                    if (predict.customerId) {
                        transport.setCustomerId(predict.customerId);
                    }

                    if (transport.setScarabContext) {
                        transport.setScarabContext(predict);
                    }

                    return transport;
                });
            }
        }

        return resolution;
    },
    create(config) {
        const windowCfg = config && config.windowConfig || {};
        let activeTransport = null;
        LOGGER_BACKEND_VERSION = config && config.loggerBackendVersion ||
            LOGGER_BACKEND_VERSION;
        DEV_MODE = config && config.dev === true || windowCfg.dev === true;
        let logLevel = DEV_MODE ? WPSLog.INFO : WPSLog.ERROR;
        let wpMerchantId = config && (config.wpmerchantid || config.wp_merchant_id) || windowCfg.wpmerchantid || windowCfg.wp_merchant_id;

        if (wpMerchantId) {
            let hashObject;
            let disableScarab;
            let preview;
            let channel;
            let mobilePreview = false;

            saveWpMerchantId(wpMerchantId);

            if (ScarabUtil) {
                hashObject = ScarabUtil.parseHashString();
                disableScarab = hashObject &&
                    (hashObject.disableScarab === 'true' || hashObject.disableScarab === true || windowCfg.disableScarab);
                preview = hashObject &&
                    (hashObject.preview === 'true' || hashObject.preview === true || windowCfg.mode === 'preview');
                logLevel = hashObject && hashObject.loglevel * 1 || windowCfg.logLevel || logLevel;

                mobilePreview = hashObject && hashObject.targetplatform === 'mobile' || windowCfg.targetPlatform === 'mobile';
            }

            WPSLog.setLevel(logLevel);
            WPSLog.info(
                `Webpersonalization build ${GITVER_HASH} - ${ESVERSION} \n${GITVER_RELEASE}${DEV_MODE
                    ? '\nDEV https://github.com/scarabresearch/webpersonalization-js/commit/' +
                    GITVER_HASH
                    : ''}`);

            preview = preview || JSON.parse(sessionStorage.getItem('preview')) === true;

            mobilePreview = mobilePreview || JSON.parse(sessionStorage.getItem('mobilePreview')) === true;

            if (preview) {
                channel = createChannel(windowCfg.channelScope);
                sessionStorage.setItem('preview', true);
                sessionStorage.setItem('mobilePreview', mobilePreview);
                const reducedConfig = { default_config: { } };

                activeTransport = new transports.PreviewTransport(
                    wpMerchantId,
                    reducedConfig,
                    channel,
                    LOGGER_BACKEND_VERSION,
                    { isMobile: mobilePreview },
                    { always_true_filters: ["language"]});
            } else if (!disableScarab) {
                let Scarab = WpScarab.getInstance();

                if (Scarab) {
                    if (DEV_MODE) {
                        Scarab.testMode();
                    }

                    const scarabLogger = new ScarabLogger(config);
                    const gaLogger = new GALoggerFrontend(window);
                    scarabLogger.merchantId = wpMerchantId;
                    store.subscribe(e => scarabLogger.listener(e));
                    store.subscribe(e => gaLogger.listener(e));

                    activeTransport = new transports.FilteringTransport(wpMerchantId, config,
                        LOGGER_BACKEND_VERSION,
                        { always_true_filters: ["language"]}, new ContentFetcher());
                } else {
                    logError('Scarab is missing!');
                }
            } else {
                logError('scarab is disabled but no preview flag');
            }
        } else {
            logError('wpMerchantId not set, farewell cruel world!');
        }

        return activeTransport;
    },
    handleAds(session, Ads) {
        Ads = Ads || [];
        WPSLog.info(Ads);
        if (!Ads.length) {
            WPSLog.warn('empty Ads array!');
            return false;
        } else {
            return Ads.map(ad => this.showAd(session, ad));
        }
    },
    go(config) {
        this.config = config;
        Features.mergeFeatures(config.features || {});
        WPSLog.info('features:', JSON.stringify(Features.getFeatures()));
        store.subscribe(e => consoleLogger.listener(e));

        let session = Session.getSession();
        let transport = this.create(config);

        storedSession = session;
        storedTransport = transport;

        if (transport) {
            this.renderAds(transport, session);
        } else {
            logError('could not initialize any transport');
        }
    },
    async renderAds(transport, session) {
        const retval = this.configureTransport(transport, session)
            .then(transport => transport.send())
            .then(Ads => this.handleAds(session, Ads));

        return retval;
    },
    reset() {
        [...document.querySelectorAll('[data-wps-ad]')].forEach(function(node) {
            delete node.dataset.wpsAd;
        });
    },
    reload() {
        this.reset();
        this.renderAds(storedTransport, storedSession);
    },
    getPredictPromise() {
        return Session.getSession().predict;
    },
    onLogin(callback) {
        store.subscribe(({ change, store }) => {
            if (change.hasOwnProperty('loggedIn') && change.loggedIn === true) {
                const prevState = store.previousPage.loggedIn;
                const currentState = change.loggedIn;
                WPSLog.info(`loginstate change (login) ${prevState} -> ${currentState}`);
                if (!prevState) {
                    callback(store);
                }
            }
        }, true);
    },
    onLogout(callback) {
        store.subscribe(({ change, store }) => {
            if (change.hasOwnProperty('loggedIn') && change.loggedIn === false) {
                const prevState = store.previousPage.loggedIn;
                const currentState = change.loggedIn;
                WPSLog.info(`loginstate change (logout) ${prevState} -> ${currentState}`);
                if (prevState === true) {
                    callback(store);
                }
            }
        }, true);
    },
    get store() {
        return store;
    }
};

export default webPersonalization;
